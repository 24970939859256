// Font family
$primary-font: 'Lato', 'Roboto', sans-serif;
$secondary-font: 'Montserrat', 'Roboto', sans-serif;
$icon-font: 'Font Awesome 6 Free'; //'Font Awesome 5 Free';

// Font weight
$font-light: 300;
$font-normal: 400;
$font-semibold: 500;
$font-bold: 600;
$font-superbold: 700;
$primary-font-bold: 700;
$font-ultrabold: 900;

// Text size
$heading-xxl: 24px;
$heading-xl: 22px;
$large-heading: 20px;
$menu-heading: 18px;
$semilarge-heading: 18px;
$message-heading: 16.55px;
$medium-heading: 15.25px; //15.1px; //15.75px
$small-heading: 13.5px;
$default-size-heading: 14.8px; // 14.5px; //14.75px;
$default-size-text: 13.32px; //12.5px;
$default-size-button-text: 12.27px;

$text-size-xxl: 26px;
$text-size-xl: 24px;
$text-size-large: 22px;
$text-size-base-rem: 16px;
$text-size-medium: 14px;
$text-size-small: 12.5px;
$text-size-tiny: 11.5px;

$text-line-height: 1.5; //1.125;
$button-line-height: 17px;
$heading-line-height: 1.68;

// Doctors on Demand brand colours
$dod-light-blue: #00b8df;
$dod-navy: #21366a;
$dod-green: #49b52c;
$dod-red: #ec2027;
$dod-pastel-yellow: #FDFD96;

// Primary (Blue tint)
$primary-color: #2d3655;
$primary-color-light: #3a4569;
$primary-color-lighter: #5d6375;
$primary-color-lightest: #c0c3cc;
$primary-color-darker: #18284c;
$primary-color-darkest: #1c223b;

// Secondary (Green tint)
$secondary-color: #40a225;
$secondary-color-lighter: #49b52c; // Green DoD brand // Figma: #44b71c
$secondary-color-lighter2: #87c577;
$secondary-color-lightest: #c6e3be;
$secondary-color-darker: #2a8616;
$secondary-color-darkest: #2b7512;
$secondary-color-hover: darken($secondary-color, 2%);

// Greyscale
$light-grey: #e7e7e7;
$light-grey2: #dbdedf;
$lighter-grey: #eef0f2;
$lightest-grey: #e7e9ee;
$marketing-banner-grey: #E8F1FD;
$off-white: #f5f6f8;
$medium-grey: #ababab;
$darker-grey: #9c9c9c;
$high-contrast-grey: #949495;
$text-grey: #bcbcbc;
$dark-grey: #343a40;
$background-grey: #fafafa;
$white: #fff;
$black: #000;

$dark-text: $primary-color;
// Seny: RBGA is inefficient, convert to static HEX colours
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);
// End -- Font config

// Theme (top nav menu)
$menu-item-color: $primary-color; // #2d3655
$menu-item-active-color: $menu-item-color; // unchanged for focus/hover
$submenu-item-bg: $menu-item-color;
$submenu-2nd-level-item-bg: #3a4569;
$submenu-bottom-border: #464f6d;
$submenu-item-hover-bg: $primary-color-light;
$submenu-item-hover-bar: $secondary-color;

// Links
$link-color: #2FB4D6; // #00b8df - Light blue DoD brand (does not pass contrast requirements)
$link-color-lighter: #92e9ff;
$link-underline-color: #99D9E9;
$link-underline-color-marketing: #00B6D9;

// Status (Error/Warning/Info)
$error-color: #ff0000;
$error-color-theme: #f44336;
$error-color-lighter: #ffb3b3;
$error-color-darker: #e32727;
$error-color-brand1: #ec2027;
$error-color-brand2: #ed1d24;
$warning-color: #eb5757;
$alert-color: #e79803;

// Weight loss traffic light
$weightloss-success: #2fa86e;
$weightloss-goal-reached: #49B52C; //#40A225
$weightloss-warning: #f48c51;
$weightloss-danger: #b63601;
$weightloss-grey: #797979;
$weightloss-title-grey: #5D6375;

// Default Shadows
$default-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);

// Default border styles
$default-border-width: 1px;
$default-border-radius: 4px;

// Default element heights
$footer-size-desktop: 64px;
$footer-size-mobile: 128px;
$modal-header-mobile: 80px;
$modal-footer-mobile: 96px; // single button

// Buttons
$default-button-height: 48px;
$short-button-height: 42px;
$button-text-color: #fff;

// Original Bootstrap grid system (must start with zero)
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

$outer-padding: 32px;
$padding-vertical: 20px;
$padding-horizontal: 32px;