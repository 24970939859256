@use 'sass:map';
@use '@angular/material' as mat;
@use 'pwa-variables.scss';

// @import '@angular/material/_theming';
// @include mat-core(); // only for Angular v15

$mat-body-1: mat.define-typography-level(
  $default-size-text,
  16px,
  $font-normal,
  $font-family: $primary-font,
  $letter-spacing: 0
);

$mat-body-2: mat.define-typography-level(
  $default-size-heading,
  18px,
  $primary-font-bold,
  $font-family: $primary-font,
  $letter-spacing: 0.01px
);

$mat-caption: mat.define-typography-level(
  $text-size-small,
  20px,
  $font-normal,
  $font-family: $primary-font,
  $letter-spacing: 0.03px
);

$mat-button: mat.define-typography-level(
  $default-size-button-text,
  18px,
  $font-normal,
  $font-family: $secondary-font,
  $letter-spacing: 0.1px
);

$mat-input: mat.define-typography-level(
  $text-size-medium,
  18px,
  $font-normal,
  $font-family: $secondary-font,
  $letter-spacing: 0
);

$mat-headline-1: mat.define-typography-level(
  $heading-xl,
  32px,
  $font-bold,
  $font-family: $secondary-font,
  $letter-spacing: 0
);

$mat-headline-2: mat.define-typography-level(
  $large-heading,
  28px,
  $font-semibold,
  $font-family: $secondary-font,
  $letter-spacing: 0
);

$mat-headline-3: mat.define-typography-level(
  $default-size-heading,
  22px,
  $font-superbold,
  $font-family: $secondary-font,
  $letter-spacing: 0
);

$mat-headline-4: mat.define-typography-level(
  $small-heading,
  19px,
  $font-normal,
  $font-family: $primary-font,
  $letter-spacing: 0
);

$mat-headline-5: mat.define-typography-level(
  $text-size-base-rem,
  18px,
  $font-normal,
  $font-family: $secondary-font,
  $letter-spacing: 0
);

$mat-headline-6: mat.define-typography-level(
  $medium-heading,
  22px,
  $font-superbold,
  $font-family: $secondary-font,
  $letter-spacing: 0.02px
);

$mat-subtitle-1: mat.define-typography-level(
  $default-size-text,
  20px,
  $font-normal,
  $font-family: $primary-font,
  $letter-spacing: 0.4px
);

$mat-subtitle-2: mat.define-typography-level(
  $medium-heading,
  26px,
  $font-bold,
  $font-family: $secondary-font,
  $letter-spacing: 0.1px
);

$mat-overline: mat.define-typography-level(
  $text-size-small,
  32px,
  $primary-font-bold,
  $font-family: $primary-font,
  $letter-spacing: 0
);

$fontConfig: (
  body-1: $mat-body-1,
  body-2: $mat-body-2,
  caption: $mat-caption,
  button: $mat-button,
  input: $mat-input,
  headline-1: $mat-headline-1,
  headline-2: $mat-headline-2,
  headline-3: $mat-headline-3,
  headline-4: $mat-headline-4,
  headline-5: $mat-headline-5,
  headline-6: $mat-headline-6,
  subtitle-1: $mat-subtitle-1,
  subtitle-2: $mat-subtitle-2,
  overline: $mat-overline
);

// Import Angular Material v16 core theme styles
// @include mat.core();

// Import theme hierarchy (makes available global .mat- class names)
@include mat.typography-hierarchy($fontConfig);

/********************************************************************
* CSS class                           Level name    Native elements *
*********************************************************************
.mat-headline-1	                      headline-1	  None
.mat-headline-2	                      headline-2	  None
.mat-headline-3	                      headline-3	  None
.mat-headline-4	                      headline-4	  None
.mat-h1 or .mat-headline-5	          headline-5	  <h1>
.mat-h2 or .mat-headline-6	          headline-6	  <h2>
.mat-h3 or .mat-subtitle-1	          subtitle-1	  <h3>
.mat-h4 or .mat-body-1	              body-1	      <h4>
.mat-h5	                              None	        <h5>
.mat-h6	                              None	        <h6>
.mat-body or .mat-body-2	            body-2	      Body text
.mat-body-strong or .mat-subtitle-2	  subtitle-2	  None
.mat-small or .mat-caption	          caption	      None
*******************************************************************/

// Start -- Theme Config
$mat-primary: (
  main: $primary-color,
  lighter: $primary-color-lightest,
  darker: $primary-color-darkest,
  50: $primary-color,
  200: $primary-color,
  // For slide toggle,
  contrast:
    (
      main: $primary-color,
      lighter: $primary-color-light,
      darker: $primary-color-darker,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: $secondary-color,
  lighter: $secondary-color-lightest,
  darker: $secondary-color-darker,
  50: $secondary-color,
  200: $secondary-color,
  // For slide toggle,
  contrast:
    (
      main: $secondary-color,
      lighter: $secondary-color-lighter,
      darker: $secondary-color-darker,
    ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: $error-color,
  lighter: $error-color-lighter,
  darker: $error-color,
  50: $error-color,
  200: $error-color,
  // For slide toggle,
  contrast:
    (
      main: $error-color,
      lighter: $error-color-lighter,
      darker: $error-color-darker,
    ),
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

/*
// $mat-secondary: (
//   main: $primary-color-lighter,
//   lighter: $primary-color-lightest,
//   darker: $primary-color-darkest,
//   200: $primary-color-lighter,
//   // For slide toggle,
//   contrast:
//     (
//       main: $secondary-color,
//       lighter: $secondary-color-lighter,
//       darker: $secondary-color-darker,
//     ),
//     // main: $light-primary-text,
//     // lighter: $dark-primary-text,
//     // darker: $light-primary-text,
// );
// $theme-secondary: mat.define-palette($mat-secondary, main, lighter, darker);

//$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);
//$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);
*/
// End -- Theme Config

// Start -- Theme Init
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  density: 0,
  // typography: mat.define-typography-config(),  // AM default typography
  typography: mat.define-typography-config(
    $font-family: $primary-font,
    $body-1: $mat-body-1,
    $body-2: $mat-body-2,
    $caption: $mat-caption,
    $button: $mat-button,
    $headline-1: $mat-headline-1,
    $headline-2: $mat-headline-2,
    $headline-3: $mat-headline-3,
    $headline-4: $mat-headline-4,
    $headline-5: $mat-headline-5,
    $headline-6: $mat-headline-6,
    $subtitle-1: $mat-subtitle-1,
    $subtitle-2: $mat-subtitle-2,
    $overline: $mat-overline
  )
));

// $altTheme: mat.define-dark-theme((color: (
//     primary: $theme-primary,
//     accent: $theme-accent,
//     warn: $theme-warn,
//   ),
//   // typography: mat.define-typography-config(),
//   density: 0,
// ));
// @include angular-material-theme($theme);

// Include Core theme
@include mat.core-theme($theme);

// Apply the primary theme to all components
@include mat.all-component-themes($theme);

// @include mat.all-component-typographies(
//   map.merge(
//     mat.define-typography-config($fontConfig),
//     ("font-family": $primary-font)
//   )
// );

// .theme-alternate {
//   @include angular-material-theme($altTheme);
// }

// End -- Theme Init
