// Global variables
@import 'src/styles/pwa-variables.scss';

// Bootstrap imports
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/_mixins.scss';

@import 'node_modules/bootstrap/scss/utilities/_align.scss';
@import 'node_modules/bootstrap/scss/utilities/_borders.scss';
@import 'node_modules/bootstrap/scss/utilities/_display.scss';
@import 'node_modules/bootstrap/scss/utilities/_flex.scss';
@import 'node_modules/bootstrap/scss/utilities/_position.scss';
@import 'node_modules/bootstrap/scss/utilities/_sizing';
@import 'node_modules/bootstrap/scss/utilities/_spacing';
@import 'node_modules/bootstrap/scss/utilities/_text';

@import 'node_modules/bootstrap/scss/_close.scss';
@import 'node_modules/bootstrap/scss/_dropdown.scss';
@import 'node_modules/bootstrap/scss/_images.scss';
@import 'node_modules/bootstrap/scss/_modal.scss';
@import 'node_modules/bootstrap/scss/_nav.scss';
@import 'node_modules/bootstrap/scss/_navbar.scss';

// Import whole Bootstrap reboot and grid library (use Bootstrap defaults)
@import 'node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import 'node_modules/bootstrap/scss/bootstrap-grid.scss';

// DoD theme configuration
@import 'src/styles/theme-config.scss';
// Special branding variable classes
@import 'src/styles/branding.scss';


//////////////////////////
// General style overrides
//////////////////////////

:root {
  html,
  body {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    min-height: 100%;
  }

  body {
    font-family: $primary-font; // 'Lato'
    font-size: $default-size-text; // $text-size-base-rem; // 16px
    line-height: 14px;
    font-weight: $font-normal;
    font-style: normal;
    font-variant: normal;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }

  ::-webkit-scrollbar {
    display: none;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

:root ::ng-deep {
  a {
    text-decoration: none !important;
    cursor: pointer;
  }

  a:hover,
  a:active {
    text-decoration: none !important;
    color: inherit;
  }

  a,
  div,
  td:focus,
  button:focus {
    outline-width: 0 !important;
  }
}

//////////////////////////
// Bootstrap styles
//////////////////////////

:root ::ng-deep {
  .img-fluid {
    max-width: 100%;
    height: auto;
  }

  .dropdown-menu {
    border-top-width: 0px;

    .dropdown-item:hover,
    .dropdown-item:focus {
      color: $primary-color;
      text-decoration: none;
      // background-color: #f8f9fa;
    }
  }
}

//////////////////////////
// Angular Material styles
//////////////////////////

// Loading spinner animation
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

//////////////////////////
// Custom Helper Styles
//////////////////////////

.nogrow {
  flex-grow: 0 !important;
}

.noshrink {
  flex-shrink: 0 !important;
}

.cangrow {
  flex-grow: 1 !important;
}

.canshrink {
  flex-shrink: 1 !important;
}
