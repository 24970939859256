@use 'pwa-variables.scss';

:host ::ng-deep {
  .bg-brand-primary {
    background: var(--brand-bg) !important;
  }

  .text-brand-secondary {
    color: var(--brand-fg) !important;
  }
}

// h1, h2, h3, h4, h5, h6 {
//   font-family: $secondary-font;
//   line-height: $heading-line-height;
// }

::ng-deep {
  h3,
  .header-title {
    font-family: $secondary-font;
    font-size: $default-size-heading;
    font-weight: $font-superbold;
    line-height: $heading-line-height;
    color: $primary-color-lighter;
  }

  a,
  span,
  mat-dialog-container,
  .mat-mdc-list-item,
  .mat-mdc-card {
    font-family: $primary-font;
  }

  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    border-color: inherit !important;
  }

  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    font-family: $secondary-font !important;
    font-weight: $font-normal;

    &:focus {
      outline: 0px solid transparent !important;
    }

    &[disabled] {
      border: 1px solid transparent !important;
    }
  }

  .mat-mdc-outlined-button {
    background-color: #fff;
    line-height: 40px;
    padding-top: 5.5px !important;
  }

  .mat-mdc-form-field-suffix {
    top: 0;
  }

  .mat-mdc-form-field-prefix,
  .mat-mdc-form-field-suffix {
    padding-top: 5px;
    align-self: center !important;
  }

  .mat-mdc-horizontal-content-container {
    padding: 0 32px;
  }

  // mat-label normal state style
  .mat-mdc-form-field-label {
    color: $text-grey !important;
  }

  // mat-label focused style
  .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label {
    color: $primary-color-lighter !important;
  }

  // Seny: we should be using the class name .mat-expansion-panel with the same effect
  mat-expansion-panel {
    background-color: transparent !important;

    .mat-expansion-panel-header:hover {
      background-color: transparent !important;
    }

    .mat-expansion-indicator,
    .mat-expansion-indicator::after {
      color: #afc0c7;
    }
  }

  .mat-mdc-tooltip {
    font-size: $text-size-small;
    /* Adjust as needed */
    max-width: 315px !important;
  }

  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .bg-primary {
    background: $primary-color !important;
  }

  .bg-secondary {
    background: $primary-color-lighter !important;
  }

  .bg-accent {
    background: $secondary-color !important;
  }

  .bg-light {
    background: $light-grey2 !important;
  }

  .bg-info {
    background: $link-color !important;
  }

  .bg-muted {
    background: $lighter-grey !important;
  }

  .bg-white {
    background-color: $white !important;
  }

  .text-primary {
    font-family: $secondary-font !important;
    color: $primary-color !important;
  }

  .text-secondary {
    color: $primary-color-lighter !important;
  }

  .text-accent {
    color: $secondary-color !important;
  }

  .text-info {
    color: $link-color !important;
  }

  .text-error {
    color: $error-color-darker;
  }

  .text-danger {
    letter-spacing: 0.37px;
  }

  .border-primary {
    border-color: $primary-color !important;
  }

  .border-secondary {
    border-color: $primary-color-lighter !important;
  }

  .border-muted {
    border-color: $lighter-grey !important;
  }

  .border-accent {
    border-color: $secondary-color !important;
  }

  .badge-accent {
    background-color: $secondary-color !important;
  }

  .font-weight-500 {
    font-weight: $font-semibold;
  }

  .font-bold-primary {
    font-family: $primary-font !important;
    font-weight: $primary-font-bold !important;
  }

  .font-bold-secondary {
    font-family: $secondary-font !important;
    font-weight: $font-semibold !important;
  }

  .spinner,
  .loading-wrapper,
  .modal-body,
  .mat-mdc-card {
    font-family: $primary-font;
  }

  // default modal body text
  .modal-body p {
    font-family: $primary-font;
    font-size: $text-size-medium; // 14px -- better readability
    // font-size: $default-size-text !important; // 13.32px
    line-height: 21px;
    color: $primary-color-lighter;
  }

  .mat-mdc-nav-list,
  .header-wrapper,
  .header-title,
  .header-text,
  .header-text > span,
  .title,
  .mat-expansion-panel-content,
  .mat-mdc-nav-list,
  .navbar-nav .nav-item,
  .nav-item > a,
  .mat-mdc-list-item,
  .mdc-list-item__content,
  .nav-bar .nav-item {
    font-family: $secondary-font !important;
    // line-height: 17px;
  }

  // Define styling for modal popups with a header containing a subheading followed by a large heading
  // .modal-wrapper .modal-header {
  //   .subtitle {
  //     font-family: $secondary-font !important;
  //     font-size: $default-size-text !important;
  //     line-height: 22px !important;
  //     font-weight: $font-light !important; // if using Lato font, set to $font-normal (400)
  //     padding-left: 0.5px !important;
  //   }
  // }

  .modal-wrapper .modal-body .mat-mdc-card-content .list-item {
    border-radius: 0 !important;
  }

  .confirmation-header,
  .modal-header,
  .header {
    display: flex;
    flex-direction: row;

    .title,
    .subtitle,
    .mat-headline-6,
    .mat-subtitle-1,
    .mat-subtitle-2 {
      display: flex;
    }

    // Card title (heavy)
    .mat-headline-6,
    .title {
      font-family: $secondary-font;
      font-weight: $font-superbold;
      font-size: $small-heading;
      line-height: 22px;
      color: $primary-color-lighter;
      margin-bottom: auto !important;

      @media (max-width: 1199px) {
        font-size: $medium-heading;
      }
    }

    .mat-headline-1 {
      @media (max-width: 1199px) {
        font-size: $large-heading;
        line-height: 23px;
        // margin-bottom: 9px !important;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: $secondary-font;
      font-weight: $font-bold;
      font-size: $heading-xl;
      color: $primary-color;
      line-height: 32px;
      margin-bottom: 0 !important;

      span {
        font-family: $secondary-font;
      }
    }

    .mat-headline-3 {
      font-family: $secondary-font;
      font-weight: $font-superbold;
      font-size: $default-size-heading;
      line-height: 22px;
      color: $primary-color-lighter;
      margin-bottom: auto;
      padding-bottom: 2px;

      span {
        font-family: $secondary-font;
      }
    }

    .mat-headline-4 {
      font-family: $primary-font;
      font-weight: $font-normal;
      font-size: $small-heading;
      line-height: 19px;
      color: $primary-color-lighter;
      margin-bottom: auto;

      span {
        font-family: $primary-font;
      }
    }

    .mat-body-1 {
      display: inline-block;
      font-family: $primary-font;
      font-weight: $font-normal;
      font-size: $default-size-text;
      line-height: 20px;
      color: $primary-color-lighter;
      // padding-top: 1px !important;

      // Bold inner text
      span {
        display: inline;
        font-family: $primary-font;
        font-size: $default-size-text;
        font-weight: $font-bold;
        line-height: 20px;
      }
    }

    // Card subtitle (light)
    .mat-subtitle-1,
    .subtitle {
      font-family: $primary-font;
      font-weight: $font-normal;
      font-size: $default-size-text;
      line-height: 16px;
      color: $primary-color-lighter;
      margin-bottom: 0;
      // padding-top: 4px;

      @media (max-width: 1199px) {
        font-size: $text-size-small;
      }
    }

    // Title that appears in a modal window, with a subtitle displayed above
    .mat-headline-2,
    .modal-header-title {
      font-family: $secondary-font;
      font-size: $heading-xxl;
      font-weight: $font-bold;
      line-height: 46px;
      padding-top: 5px;
    }

    // Subtitle that appears above the title in a modal window
    .modal-header-subtitle {
      font-family: $secondary-font;
      font-weight: $font-light; // if using Lato font, set to $font-normal (400)
      font-size: $default-size-text;
      line-height: 22px;
      padding-left: 0.5px;
      // padding-bottom: 2px;

      + .modal-header-title {
        line-height: 22px !important;
        padding-top: 4px !important;
      }
    }

    // Large heading used in modal header that also has a subtitle above
    .header-title {
      font-family: $secondary-font;
      font-size: $heading-xl;
      font-weight: $font-bold;
      line-height: 28px;
      color: $primary-color;

      @media (max-width: 1199px) {
        font-size: $large-heading;
        line-height: 23px;
      }
    }

    .mat-subtitle-2 {
      padding-bottom: 10px;
    }

    // Inline subtitle using secondary font (dashboard cards only)
    .subtitle-dashboard {
      font-family: $secondary-font;
      font-size: $text-size-small;
      font-weight: $font-normal;
      line-height: 18px;
      margin-top: 2px;
      color: $primary-color-lighter;
    }

    // Description text under a small size heading, add margin between the heading and text
    .desc {
      display: block;
      margin-top: 4px;
      line-height: 19px;
    }
  }

  // List item colours (primary text)
  .cdk-overlay-pane .mdc-list-item__primary-text,
  mat-toolbar .mdc-list-item__primary-text,
  .stepper-nav-items .mdc-list-item__primary-text {
    font-family: $secondary-font !important;

    span {
      font-family: $secondary-font !important;
    }

    a {
      cursor: pointer !important;
    }
  }

  mat-toolbar .mdc-list-item__primary-text {
    color: $primary-color;
  }

  .stepper-nav-items .mdc-list-item__primary-text {
    color: $off-white;
  }

  .stepper-main-wrapper {
    mat-progress-bar {
      background-color: $primary-color;
    }

    mat-progress-bar,
    .mdc-linear-progress__bar,
    .mdc-linear-progress__buffer {
      height: 8px !important;
    }

    .mdc-linear-progress__bar .mdc-linear-progress__bar-inner {
      border-top-width: 8px !important;
      border-color: $secondary-color !important;
    }
  }

  .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.12);
    }
  }

  .mat-mdc-button,
  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    font-size: $default-size-button-text;
    font-family: $secondary-font !important;
    font-weight: $font-normal;
    line-height: 17px;

    &:focus {
      outline: 0px solid transparent !important;
    }
  }

  .mat-mdc-raised-button,
  .mat-mdc-outlined-button,
  .mat-mdc-unelevated-button {
    border-color: $secondary-color !important;
  }

  .mat-mdc-outlined-button {
    background-color: $white;
  }

  mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    border: 0;

    &.separate-buttons {
      mat-button-toggle.mat-button-toggle-appearance-standard {
        border: 1px solid $secondary-color !important;
      }
    }

    &.inline-toggle-group {
      mat-button-toggle.mat-button-toggle-appearance-standard:first-child {
        margin-right: 0 !important;
      }
    }

    mat-button-toggle.mat-button-toggle-appearance-standard {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden !important;
      padding: 2px 6px 0 6px;

      .mat-button-toggle-button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        // Cater for long text that will wrap onto multiple lines
        .mat-button-toggle-label-content {
          white-space: normal;
          line-height: 16px !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  // Standardise styling of all toggle buttons
  // Assumes buttons are separated and have individual borders
  // An alternative, inline toggle button style
  .mat-button-toggle {
    font-family: $secondary-font !important;
    font-size: $default-size-button-text;
    text-align: center;
    border: 1px solid $secondary-color;
    border-radius: 4px;
    color: $secondary-color;

    &.mat-button-toggle-checked {
      color: $white !important;
    }

    &:first-child {
      margin-right: 8px; // default margin between a two-button toggle
    }

    // align the ripple effect with the entire length of the button
    .mat-ripple.mat-button-toggle-ripple {
      display: flex !important;
    }
  }

  // Remove top and bottom padding from drop-down lists
  .cdk-overlay-container .cdk-overlay-pane .mat-mdc-select-panel.mdc-menu-surface {
    padding: 0 !important;
    margin: 2px 0 0 0 !important;
  }

  .mat-button-toggle-focus-overlay {
    border: none;
  }

  mat-datepicker-toggle {
    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
      border-radius: 4px !important;
    }
  }

  .inline-toggle-group-wrapper {
    mat-button-toggle-group {
      width: 100%;
      border: 0;

      .mat-button-toggle {
        width: 100%;
        font-size: $default-size-button-text;
        font-family: $secondary-font;
        font-weight: $font-normal;
        color: $secondary-color;
        white-space: break-spaces !important;
        line-height: normal;
        padding: 0;

        &.mat-button-toggle-appearance-standard {
          border-radius: 0;
          margin: 0 !important;
          border: 1px solid $secondary-color !important;
          // height: 39px !important;

          &:first-child {
            border-top-left-radius: 4px !important;
            border-bottom-left-radius: 4px !important;
          }

          &:last-child {
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
          }

          &:not(:last-child) {
            border-right: 0 !important;
          }
        }

        .mat-button-toggle-button {
          border: 0;

          .mat-button-toggle-label-content {
            // Seny - Improve readability of toggle button text
            font-weight: $font-normal !important;
            letter-spacing: 0.2px !important;
            white-space: normal !important;
            padding-top: 2px !important;
          }
        }

        &:not(:first-child) {
          border-left: 1px solid $secondary-color;
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        .mat-button-toggle-focus-overlay {
          border-bottom: none;
        }
      }

      .mat-button-toggle-checked {
        background-color: $secondary-color;

        button {
          color: $white !important;
        }
      }
    }
  }

  // Google Maps Address search component
  address-autocomplete {
    .mat-mdc-form-field-icon-suffix > i:before {
      font-size: 20px;
      height: 30px;
      width: 30px;
      display: block;
      margin-top: 11px;
      margin-right: 1px;
    }
  }

  .mat-mdc-form-field-outline {
    background: $white;
    border-radius: 4px;
  }

  .mdc-text-field--outlined .mdc-floating-label {
    font-family: $secondary-font !important;
    will-change: auto !important; // fixes issue with blurry scaled-down floating labels
  }

  // mat-label normal state style
  // Seny - check if the following two styles are actually being applied:
  .mat-mdc-form-field-label {
    font-family: $secondary-font;
    font-size: $text-size-medium;
    line-height: 17px;
    color: $medium-grey !important; //#bcbcbc !important;
  }

  // mat-label focused style
  .mat-mdc-form-field.mat-mdc-focused .mat-mdc-form-field-label {
    color: $primary-color-lighter !important;
  }

  // Fix the height of input form elements with space for error messages
  .mat-mdc-form-field {
    height: 69px !important;
    margin-bottom: 10px;
  }

  // Fix the inner height of input elements
  .mat-mdc-form-field-flex {
    height: 48px;

    .mat-mdc-form-field-infix {
      min-height: unset !important;
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding-top: 3px;
    }
  }

  mat-form-field.tall-field {
    .mat-mdc-form-field-flex {
      height: 54px;

      .mat-mdc-form-field-infix {
        height: 54px;
      }
    }
  }

  // Center position floating labels on the input border
  .mat-mdc-floating-label {
    top: 24.5px !important;

    &.mdc-floating-label--float-above {
      // If we change the scaling and the font size of the floating label, it ends up overlapping the input
      // border. Scale must remain at the default 0.75, and then we can adjust its font size without overlap
      // --mat-mdc-form-field-floating-label-scale: 1.0 !important;
      // font-size: 11.75px !important;
      top: 27.5px !important;
      font-size: 14.75px !important;
    }
  }

  // Form control: Input
  .mat-mdc-form-field-infix {
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0 !important;

    input.mat-mdc-form-field-input-control.mat-mdc-input-element {
      padding-top: 0px !important;
      line-height: 16px !important;
    }

    input.mdc-form-field-input-control.mdc-text-field__input {
      padding-top: 3px !important;
    }
  }

  .mdc-text-field__select,
  .mdc-text-field__input {
    font-family: $secondary-font !important;
  }

  // Standardise secondary font and size for all input fields
  .mat-mdc-datepicker,
  .mat-mdc-select,
  .mat-mdc-select-value,
  .mat-mdc-select-value-text,
  .mat-mdc-select-min-line,
  .mat-mdc-form-field-infix,
  textarea,
  select,
  input {
    font-family: $secondary-font !important;
    font-size: $text-size-medium !important;
    color: $primary-color;
  }

  // Phone input contains only digits - these look better with extra letter spacing
  input[type='tel'] {
    letter-spacing: 1.5px !important;
  }

  // Match the inner border radius of input fields with the outer radius so that backgrounds don't bleed through
  mat-form-field.mat-mdc-form-field-type-mat-input,
  mat-form-field.mat-mdc-form-field-type-mat-select {
    background-color: inherit;

    .mat-mdc-text-field-wrapper {
      border-radius: $default-border-radius;
    }
  }

  mat-form-field.mat-mdc-form-field-type-mat-select {
    background-color: $white;
  }

  // Alter background colour of input fields that appear on a gray background
  .gray-box,
  .list-item-question {
    mat-form-field.mat-mdc-form-field-type-mat-input,
    mat-form-field.mat-mdc-form-field-type-mat-select {
      background-color: $lighter-grey;
    }
  }

  // Center contents in text input fields
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 18px !important;
    padding-bottom: 14px !important;
    top: 0 !important;
  }

  // Hint text below input fields (eg. Medicare IRN text and link)
  .hint-bottom {
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.6);
    font-size: $text-size-small;
    line-height: 16px;
    margin-top: 16px;
    top: 0;

    .text-accent {
      display: inline;
      border-bottom: 1px dotted $secondary-color;
      cursor: help;
    }

    a.text-accent {
      cursor: pointer;
      pointer-events: all;
      text-decoration: none;
    }
  }

  .sub-label {
    a.text-accent {
      cursor: pointer;
      pointer-events: all;
      text-decoration: none;
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    pointer-events: none;
  }

  // Angular Materials Input Form Field component
  mat-form-field.mat-mdc-form-field-type-mat-select {
    height: 48px !important;

    .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
      // padding: 16px 0 0 5px !important;
      top: -2px !important;
    }
  }

  // Angular Materials inner Select element
  mat-select {
    .mat-mdc-select-arrow-wrapper {
      height: auto !important;
      width: auto !important;
      margin-top: 1.5px !important;
      transform: none !important;

      .mat-mdc-select-arrow {
        // Match the size of the Angular Materials caret to the Font Awesome caret
        width: 13px !important;
        height: 18px !important;
        margin: 0 !important;
        border: none !important;

        // Hide the Angular Materials caret
        > svg {
          display: none !important;
        }

        // Display Font Awesome caret
        &:after {
          font-family: $icon-font;
          font-weight: $font-superbold;
          content: '\f078';
          font-size: 14px;
        }
      }
    }
  }

  .mat-mdc-select-placeholder.mat-mdc-select-min-line {
    padding-left: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    background-color: $white !important;
  }

  .mat-mdc-form-field-error {
    display: flex !important;
    font-family: $primary-font;
    font-size: $text-size-small;
    line-height: 14px;

    // If there's a warning icon, align it to the error text
    > .mat-icon {
      margin-right: 3px;
      margin-top: 3.6px;
    }
  }

  .mat-mdc-option,
  .mat-mdc-option span {
    font-family: $secondary-font !important;
    font-size: $text-size-medium;
  }

  .mat-expansion-panel {
    box-shadow: none;

    .mat-expansion-indicator,
    .mat-expansion-indicator::after {
      color: $primary-color-lighter !important;
    }
  }

  .mat-mdc-dialog-container,
  .questionnaire-footer,
  .confirmation-footer,
  .modal-footer,
  .button-footer,
  .content-footer,
  .mobile-action-button,
  .action-buttons,
  .button-container,
  .button-header,
  .button-wrapper {
    mat-button-toggle {
      height: $default-button-height;
      width: 100%;
      padding: 3px 6px 0 6px;
      border-radius: 4px;
      // font-size: $default-size-button-text; // 13.7px is better for Yes/No type buttons
      letter-spacing: 0.5px;
      border: 1px solid $secondary-color;

      .mat-button-toggle-label-content {
        font-size: $default-size-button-text;
        font-family: $secondary-font !important;
        font-weight: $font-normal;
        line-height: 17px;
        letter-spacing: 0.5px;
      }

      &.mat-mdc-outlined-button {
        background-color: $white;
      }

      &.multiline {
        .mdc-button__label {
          font-size: $default-size-button-text;
          line-height: 17px;
          padding: 0px 5px 2px 5px;
          white-space: normal !important;

          @media (min-width: 1200px) {
            line-height: 15px;
            padding: 4px 6px 4px 6px;
          }
        }
      }

      &.short-button {
        height: $short-button-height !important;
        padding: 0;

        .mdc-button__label {
          @media (min-width: 1200px) {
            line-height: 15px !important;
            padding: 4px 6px 4px 6px !important;
          }
        }
      }
    }

    .mat-mdc-button-base[disabled],
    .mat-mdc-button-disabled {
      border: 1px solid transparent !important;
      background-color: var(
        --mdc-filled-button-disabled-container-color
      ) !important; // rgb(0 0 0 / 12%) !important; // #0000001f !important;
      color: var(
        --mdc-filled-button-disabled-label-text-color
      ) !important; // rgb(0 0 0 / 26%) !important; // #00000042 !important;
      padding: 3px 5px 0 5px !important;
      cursor: auto;
    }

    .toggle-group {
      border: none;
      width: 100%;
      height: 49px; // 1px larger than the inner buttons for overflow control

      .mat-button-toggle-focus-overlay {
        border: none;
      }

      .mat-button-toggle-checked {
        background-color: $secondary-color;

        span {
          color: $white;
        }
      }
    }

    mat-button-toggle-group {
      border: 0;
      min-height: 48px;
    }

    mat-button-toggle-button {
      height: $default-button-height;

      .mat-button-toggle-label-content {
        font-family: $secondary-font !important;
        font-size: 13.7px !important;
        line-height: 19px !important;
        letter-spacing: 0.5px;
      }
    }

    button.mat-mdc-button-base {
      height: $default-button-height !important;
      width: 100%;
      padding: 3px 6px 0 6px !important;
      border-radius: $default-border-radius !important;
      border: 1px solid $secondary-color !important;
      cursor: pointer;

      span,
      .mdc-button__label {
        font-family: $secondary-font !important;
        font-weight: $font-normal !important;
        line-height: 17px !important;
      }

      .mdc-button__label {
        font-size: $default-size-button-text !important;
        letter-spacing: 0.5px;
      }

      // outlined button (white)
      &.mat-mdc-outlined-button {
        background-color: $white;
        color: $secondary-color;
      }

      // unelevated button (green)
      &.mat-mdc-unelevated-button {
        background-color: $secondary-color;
        color: $white;
      }

      &.short-button {
        @media (min-width: 1200px) {
          height: $short-button-height !important;
          line-height: 15px !important;
        }
      }

      &.multiline {
        padding: 0 !important;

        .mdc-button__label {
          font-size: $default-size-button-text;
          line-height: 17px;
          padding: 1.5px 5px 2px 5px;
          white-space: normal;

          @media (min-width: 1200px) {
            line-height: 16px !important;
            padding: 4px 8px 1px 8px !important;

            span {
              font-size: $default-size-button-text;
              line-height: 16px !important;
            }
          }
        }
      }

      &.multiline.short-button {
        .mdc-button__label {
          @media (min-width: 1200px) {
            line-height: 15px !important;
            padding: 5px 6px 3px 6px !important;

            span {
              line-height: 15px !important;
            }
          }
        }
      }
    }
  }

  .disabled-button-wrapper {
    .mat-mdc-button-base[disabled].disabled-btn-grey-bg,
    .mat-mdc-button-disabled.disabled-btn-grey-bg {
      background-color: $off-white;
      color: $high-contrast-grey !important;
      border: 1px solid $high-contrast-grey !important;
    }
  }

  .disabled-input-wrapper {
    .mat-mdc-form-field.disabled {
      .mat-mdc-text-field-wrapper {
        background-color: $off-white !important;
      }
    }
  }

  .action-buttons .long.mat-mdc-button-base {
    height: $short-button-height;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }

  // Default background for Card elements
  .card {
    background-color: $white;
  }

  // Default background for radio buttons
  .mat-mdc-radio-group .mat-mdc-radio-outer-circle {
    background-color: $white;
  }

  // Background for "gray-box" items
  .gray-box-question-wrapper .list-item {
    background-color: $lighter-grey;
  }

  // Match colour of map icon in address field to the default suffix icon
  .mat-mdc-form-field-icon-suffix {
    i.fa-map-marker-alt {
      color: #757575 !important;
    }
  }

  // Remove bottom margin to align hint text with error label under address input field
  .address-input.mat-mdc-form-field-type-mat-input {
    margin-bottom: -17px !important;

    .mat-mdc-form-field-error-wrapper {
      right: auto;
    }

    .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
      width: calc(100% - 80px);
    }

    .mat-mdc-form-field-error {
      width: auto;
      display: inline-block !important;
      margin-top: -17px;
    }
  }

  // Mobile menu hamburger icon
  .hamburger-icon {
    > .mat-mdc-icon-button.mat-mdc-button-base {
      width: 28px !important;
      height: 28px !important;
      top: 6px !important;
      padding: 7px 6px 4px 7px !important;
      width: 48px !important;
      height: 48px !important;

      mat-icon {
        font-size: 28px !important;
        width: 28px !important;
        height: 28px !important;
      }
    }
  }

  .signup-wrapper,
  .stepper-sidenav-wrapper {
    .caption,
    .sub-caption {
      font-family: $secondary-font !important;
    }

    .sub-caption {
      font-size: 12.1px !important;
      line-height: 14px !important;
    }
  }

  // Floating a label to the top of an input field needs to be slower/smoother (the 150ms default is too fast)
  .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label {
    // transform (translateY), colour
    transition-duration: 300ms, 300ms !important;
  }

  // Normalise Form headings and sub-headings
  .mobile-form-header,
  .mobile-header-section .title-section,
  .header-section .header {
    .title {
      font-size: $default-size-heading !important;
      font-weight: $font-superbold !important;
      color: $primary-color-lighter !important;
      line-height: 22px;
    }

    .desc {
      font-size: $default-size-text !important;
      color: $primary-color-lighter !important;
      line-height: 20px;
      padding-top: 6px;
    }
  }

  .questionnaire-section .questionnaire-wrapper {
    box-shadow: $default-box-shadow;
  }

  // Remove arrow in mat-input type number in Chrome, Safari, Edge and Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .mat-datepicker-input.mat-mdc-input-element {
    letter-spacing: 1px;
  }

  .info-icon {
    height: 15px;
    width: 15px;
  }

  .mdc-tooltip__surface {
    white-space: pre-line;
  }

  .error-message {
    font-family: $primary-font;
    font-size: $default-size-text;
    color: $error-color;
    margin-bottom: 0px;
    margin-top: 8px;
  }
}
